@import "../../../../theme/mixins.scss";

.Trait {
  margin-bottom: 4px;
  box-sizing: border-box;
  border-radius: 5px;
  width: 94%;
  min-height: 58px;
  padding: 6px;
  display: inline-block;
  max-width: 270px;
}

.TraitTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TraitTitle {
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  padding-bottom: 2px;
  @include theme-aware('color', 'color-twentyfour');
}

.TraitRarity {
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: right;
  @include theme-aware('color', 'color-two');
}

.TraitText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.5px;
  word-wrap: break-word;
  @include theme-aware('color', 'color-eleven');
}

.TraitSpacer {
  height: 6px;
  width: 100%;
}
