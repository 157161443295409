@import "../../theme/mixins.scss";

.ModalContainer {
  display: flex;
}

.ModalContainerCollection {
  width: 59px;
  height: 59px;
  flex-grow: 1;
  box-shadow: 0 0 6px 3px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background 0.4s, stroke 0.4s;
  cursor: pointer;
  @include theme-aware('background', 'color-one');
  @include theme-aware('color', 'color-nine');
  @include theme-aware('stroke', 'color-fifteen');
}

.ModalContainerCollection:hover {
  @include theme-aware('background', 'color-fifteen');
  @include theme-aware('stroke', 'color-one');
}

.ModalContainerIcon {
  box-shadow: 0 0 2px 0;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  margin-left: 15px;
  overflow: hidden;
  position: relative;
  @include theme-aware('color', 'color-fourteen');
  @include theme-aware('background', 'color-thirteen');
}

.ModalContainerIconSrc {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ModalContainerText {
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 2.13px;
  margin-left: 20px;
  @include theme-aware('color', 'color-twentyone');
}

.ModalContainerCollection:hover > .ModalContainerText {
  @include theme-aware('color', 'color-one');
}

.CollectionModal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  @include theme-aware('background', 'color-one');
  overflow: scroll;
}

.FilterModal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  @include theme-aware('background', 'color-one');
  overflow: scroll;
}

.FilterModalContainer {
  border-radius: 8px;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 2.13px;
  transition: background 0.2s, color 0.2s, fill 0.2s, stroke 0.2s;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  @include theme-aware('background', 'color-one');
  @include theme-aware('color', 'color-two');
  transition: background 0.2s, color 0.2s;
  cursor: pointer;
}

.FilterModalContainer:hover {
  @include theme-aware('background', 'color-two');
  @include theme-aware('color', 'color-one');
}
