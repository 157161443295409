@import "../../theme/mixins.scss";

.TopBar {
  display: flex;
  margin-bottom: 30px;
}

.TopSpacer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.TopSpacerM {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.Logo {
  cursor: pointer;
}

.TopSpacerText {
  font-family: Roboto;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 1.54px;
  padding-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include theme-aware('color', 'color-eleven')
}

.TopSpacerContainer {
  font-family: Roboto;
  font-weight: 700;
  font-size: 10px;
  box-shadow: 0 0 6px 3px;
  border-radius: 8px;
  margin-right: 30px;
  padding: 8px;
  box-sizing: border-box;
  @include theme-aware('background', 'color-one');
  @include theme-aware('color', 'color-nine');
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 100px;
}

.TopSpacerMobile {
  font-family: Roboto;
  font-weight: 700;
  font-size: 10px;
  box-shadow: 0 0 6px 3px;
  border-radius: 8px;
  margin-right: 30px;
  padding: 8px;
  box-sizing: border-box;
  @include theme-aware('background', 'color-one');
  @include theme-aware('color', 'color-nine');
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 100px;
}

@media only screen
  and (max-width: 1023px) { 
  .TopSpacerContainer {
    display: none;
  }

  .TopSpacer {
    display: none;
  }
}

@media only screen
  and (min-width: 1023px) { 
  .TopSpacerMobile {
    display: none;
  }

  .TopSpacerM {
    display: none;
  }
}

.TopSpacerHint {
  position: absolute;
  top: 10px;
  left: 0px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  @include theme-aware('color', 'color-two');
}

.PriceSymbol {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding-right: 5px;
  @include theme-aware('stroke', 'color-two');
}
