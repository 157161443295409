@import "../../theme/mixins.scss";

.MenuItem {
  width: 59px;
  height: 59px;
  box-shadow: 0 0 6px 3px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  transition: background 0.4s, stroke 0.4s;
  cursor: pointer;
  @include theme-aware('background', 'color-one');
  @include theme-aware('color', 'color-nine');
  @include theme-aware('stroke', 'color-fifteen');
}

.MenuItem:hover {
  @include theme-aware('background', 'color-fifteen');
  @include theme-aware('stroke', 'color-one');
}