@import '../../../../theme/mixins.scss';

.Details {
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
}

.NFTInfo {
  flex-grow: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 45px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.TopLevel {
  display: flex;
  margin-top: 7px;
  margin:auto;
  max-width: 240px;
}

.BadgeContainer {
  display: flex;
  padding-left: 6px;
}

.BadgeContainer > * {
  margin-right: 5px;
}

.Collection {
  font-family: Roboto;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1.84px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 240px;
  cursor: pointer;
  @include theme-aware('color', 'color-two');
}

.Name {
  margin:auto;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 2.1px;
  padding-top: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 240px;
  cursor: pointer;
  @include theme-aware('color', 'color-eleven');
}

.Attributes {
  display: flex;
  width: 44px;
  height:  42px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  transition: background 0.2s, stroke 0.2s;
  @include theme-aware('stroke', 'color-two');
  @include theme-aware('background', 'color-one');
}

.Attributes:hover {
  @include theme-aware('stroke', 'color-one');
  @include theme-aware('background', 'color-two');
}