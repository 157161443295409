@import "../../theme/mixins.scss";

.Transfer {
  box-shadow: 0 0 12px 7px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 292px;
  height: 467px;
  display: flex;
  flex-direction: column;
  padding: 11px;
  position: relative;
  @include theme-aware('color', 'color-nine');
  @include theme-aware('background', 'color-one');
}

.TransferImage {
  flex-grow: 1;
  background: #3C3C3C;
  box-shadow: 0 0 12px 7px rgba(0,0,0,0.06);
  border-radius: 6px;
}

.TransferCollection {
  font-family: Roboto;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1.84px;
  margin: auto;
  margin-top: 10px;
  @include theme-aware('color', 'color-two');
}

.TransferDescription {
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 2.1px;
  margin: auto;
  margin-top: 10px;
  @include theme-aware('color', 'color-eleven');
}

.TransferHelpers {
  font-family: Menlo-Regular;
  font-size: 12px;
  color: #707071;
  letter-spacing: 0.93px;
}

.TransferHighlight {
  font-family: Menlo-Regular;
  font-size: 12px;
  letter-spacing: 0.93px;
  color: #2081E2;
}

.TransferInputContainer {
  margin-top: 12px;
  width: 100%;
}

.TransferInput {
  border: 1px solid #979797;
  border-radius: 6px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1.23px;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  @include theme-aware('color', 'color-two');
  @include theme-aware('background', 'color-one');
}

.TransferInputError {
  @include theme-aware('border-color', 'color-twentythree');
}

.TransferButton {
  margin-top: 12px;
}

.TransferError {
  padding-bottom: 4px;
  @include theme-aware('color', 'color-twentythree');
}

.TransferClose {
  position: absolute;
  box-shadow: 0 0 2px 0px;
  top: -14px;
  right: -14px;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  cursor: pointer;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.4s, stroke 0.4s, color 0.4s, background 0.4s;
  @include theme-aware('background', 'color-one');
  @include theme-aware('color', 'color-nine');
  @include theme-aware('stroke', 'color-twenty');
}

.TransferClose:hover {
  box-shadow: 0 0 5px 0px;
  @include theme-aware('stroke', 'color-one');
  background: #FF3B30;
}