.ProfileView > * {
  /*margin-bottom: 30px;*/
}

.ProfileViewContainer {
  display: flex;
}

.ProfileViewFilters > * {
  margin-bottom: 30px;
}

.ProfileViewCardContainer {
  flex-grow: 1;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px;
  margin-top: -20px;
}

.ProfileViewCardContainer > * {
  margin: 30px;
  margin-top: 20px;
}

.ProfileViewTopBar {
  display: flex;
  margin-bottom: 30px;
}

.ProfileViewSearch {
  flex-grow: 1;
  margin-right: 30px;
}

@media only screen
  and (max-width: 600px) { 
  .ProfileViewCardContainer > * {
    margin: 20px 0 30px 0;
  }
}