@import "../../theme/mixins.scss";

.Login {
  box-shadow: 0 0 6px 3px;
  border-radius: 8px;
  width: 150px;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 30px;
  transition: background 0.4s, color 0.4s, stroke 0.4s, fill 0.4s;
  @include theme-aware('color', 'color-nine');
  @include theme-aware('background', 'color-one');
}

@media only screen
  and (max-width: 1023px) { 
  .Login {
    display: none;
  }
}

.Login > .LoginIcon {
  @include theme-aware('color', 'color-fifteen');
  @include theme-aware('stroke', 'color-fifteen');
  @include theme-aware('fill', 'color-fifteen');
}

.Login > .LoginText {
  @include theme-aware('color', 'color-fifteen');
  @include theme-aware('stroke', 'color-fifteen');
  @include theme-aware('fill', 'color-fifteen');
}

.Login:hover {
  @include theme-aware('background', 'color-sixteen');
}

.Login:hover .LoginIcon {
  @include theme-aware('color', 'color-twenty');
  @include theme-aware('stroke', 'color-twenty');
  @include theme-aware('fill', 'color-twenty');
}

.Login:hover > .LoginText {
  @include theme-aware('color', 'color-twenty');
  @include theme-aware('stroke', 'color-twenty');
  @include theme-aware('fill', 'color-twenty');
}

.LoginIcon {
  width: 16px;
  height: 16px;
}

.LoginText {
  font-family: Roboto;
  font-weight: 400;
  font-size: 20px;
  
  letter-spacing: 1.77px;
  padding-left: 15px;
} 