@import "../../theme/mixins.scss";

.ErrorPage {
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ErrorPageText {
  padding-top: 35px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 1.54px;
  padding-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include theme-aware('color', 'color-two')
}