@import '../../../theme/mixins.scss';

.Select {
  position: absolute;
  top: 10px;
  right: 10px;
}

.Select > * {
  margin: 10px;
}

.Selector {
  border-radius: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s, stroke 0.2s;
  opacity: 0.95;
  @include theme-aware('stroke', 'color-two');
  @include theme-aware('background', 'color-one');
}

.Selector:hover {
  @include theme-aware('stroke', 'color-one');
  @include theme-aware('background', 'color-two');
}