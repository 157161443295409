@import "../../theme/mixins.scss";

@keyframes show_one {
  0% {
    opacity: 0.2;
  }
  33% {
    opacity: 1.0;
  }
  66% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes show_two {
  0% {
    opacity: 0.2;
  }
  33% {
    opacity: 0.2;
  }
  66% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.2;
  }
}


@keyframes show_three {
  0% {
    opacity: 1.0;
  }
  33% {
    opacity: 0.2;
  }
  66% {
    opacity: 0.2;
  }
  100% {
    opacity: 1.0;
  }
}

.LoadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Loading {
  width: 100px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.LoadingElement {
  box-shadow: 0 0 10px 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  @include theme-aware('color', 'color-fourteen');
  @include theme-aware('background', 'color-one');
}

.LoadingOne {
  animation-name: show_one;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
}

.LoadingTwo {
  animation-name: show_two;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
}

.LoadingThree {
  animation-name: show_three;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
}