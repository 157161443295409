@import "../../theme/mixins.scss";

.Dropdown {
  box-sizing: border-box;
  width: 430px;
  height: 59px;
  position: relative;
  z-index: 999;
  margin-bottom: 30px;
}

.DropdownFiter {
  box-shadow: 0 0 6px 3px;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 430px;
  overflow: hidden;
  transition: height 0.4s;
  position: absolute;
  padding-left: 30px;
  padding-right: 30px;
  @include theme-aware('color', 'color-nine');
  @include theme-aware('background', 'color-one');
}

.DropdownFiterOpen {
  height: 295px;
}

.DropdownFiterOpenWithRarity {
  height: 415px;
}

.DropdownFiterClosed {
  height: 59px;
}

.DropdownFiterText {
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1.23px;
  text-align: left;
  flex-grow: 1;
  @include theme-aware('color', 'color-twentyone');
}

.DropdownFiterCarretContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 59px;
  padding-right: 4px;
}

.DropdownFiterCarret {
  transform: rotate(-90deg);
}

@keyframes example {
  0% {opacity: 0.0;}
  50% {opacity: 0.0;}
  100% {opacity: 1.0;}
}

.DropdownDefault{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 59px;
  box-sizing: border-box;
  animation-name: example;
  animation-duration: 0.4s;
  cursor: pointer;
}

.DropdownSelected {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 59px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  transition: color 0.4s, background 0.4s;
  cursor: pointer;
  animation-name: example;
  animation-duration: 0.4s;
}

.DropdownSelected:hover {
  @include theme-aware('color', 'color-one');
  @include theme-aware('background', 'color-two');
}
