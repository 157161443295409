@import '../../../theme/mixins.scss';

.Back {
  background: #FFFFFF;
  box-shadow: 0 0 6px 3px;
  border-radius: 8px;	
  width: 295px;
  height: 407px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 12px;
  position: relative;
  @include theme-aware('color', 'color-nine');
  @include theme-aware('background', 'color-one');
}

.SelectorContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.Selector {
  border-radius: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s, stroke 0.2s;
  border: 1px solid #E3E3E3;
  opacity: 1;
  @include theme-aware('border-color', 'color-ten');
  @include theme-aware('background', 'color-one');
  @include theme-aware('stroke', 'color-two');
}

.Selector:hover {
  @include theme-aware('background', 'color-two');
  @include theme-aware('stroke', 'color-one');
}

.TraitsContainer {
  flex-grow: 1;
  width: 100%;
  overflow-y: scroll;
}

.TraitsActionContainer {
  padding-top: 10px;
  width: 100%;
  box-sizing: border-box;
}