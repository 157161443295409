@import '../../../theme/mixins.scss';

.Front {
  box-shadow: 0 0 6px 3px;
  border-radius: 8px;	
  width: 295px;
  height: 407px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 12px;
  position: relative;
  @include theme-aware('color', 'color-nine');
  @include theme-aware('background', 'color-one');
}

.CardImgWrapper {
  cursor: pointer;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.test {
  width: 100%;
  height: 100%;
}