.MarketplaceContainer {
  display: flex;
  margin-top: 30px;
}

.MarketplaceCardContainer {
  flex-grow: 1;
  height: calc(100vh - 105px);
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  overflow-x: hidden;
  justify-content: center;
  margin-top: -20px;
}

.MarketplaceCardContainer > * {
  margin: 30px;
}

.MarketplaceTopBar {
  display: flex;
}

.MarketplaceSearch {
  flex-grow: 1;
  margin-right: 30px;
}

.ScrollContainer {
  width: 100%;
  padding: 20px;
  margin-top: -60px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ScrollContainer > * {
  margin: 30px;
}

@media only screen
  and (max-width: 1023px) { 
  .MarketplaceViewSelector {
    display: none;
  }

  .MarketplaceTopBar {
    display: none;
  }

  .MarketplaceCardContainer {
//  height: auto;
    overflow: auto;
    overflow-x:hidden;
  }
}

@media only screen
  and (min-width: 1024px) { 
  .MarketplaceViewSelectorMobile {
    display: none;
  }
}
