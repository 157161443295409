@import "../../theme/mixins.scss";

.PageWrapper {
  @include theme-aware('background', 'color-twentytwo');
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  overflow: auto;
}

.PageWrapperContainer {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1500px;
  height: auto;
  box-sizing: border-box;
  margin: 0 auto;
  @include theme-aware('background', 'color-twentytwo');
}