@import "../../theme/mixins.scss";

.List {
  box-shadow: 0 0 12px 7px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 292px;
  height: 545px;
  display: flex;
  flex-direction: column;
  padding: 11px;
  position: relative;
  @include theme-aware('color', 'color-nine');
  @include theme-aware('background', 'color-one');
}
  

.ListImage {
  flex-grow: 1;
  background: #3C3C3C;
  box-shadow: 0 0 12px 7px rgba(0,0,0,0.06);
  border-radius: 6px;
}

.ListCollection {
  font-family: Roboto;
  font-weight: 700;
  font-size: 14px;
  color: #999999;
  letter-spacing: 1.84px;
  margin: auto;
  margin-top: 10px;
  @include theme-aware('color', 'color-two');
}

.ListDescription {
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
  letter-spacing: 2.1px;
  margin: auto;
  margin-top: 10px;
  @include theme-aware('color', 'color-eleven');
}

.ListHelpers {
  font-family: Menlo-Regular;
  font-size: 12px;
  color: #707071;
  letter-spacing: 0.93px;
}

.ListHighlight {
  font-family: Menlo-Regular;
  font-size: 12px;
  letter-spacing: 0.93px;
  color: #2081E2;
}

.ListHighlightContainter {
  padding-top: 12px;
  padding-bottom: 12px;
}

.ListButton {
  margin-top: 12px;
}

.ListInputContainer {
  position: relative;
  width: 100%;
}

.ListInput {
  border: 1px solid #979797;
  border-radius: 6px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1.23px;
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
  padding-left: 33px;
  @include theme-aware('color', 'color-two');
  @include theme-aware('background', 'color-one');
}

.ListInputError {
  @include theme-aware('border-color', 'color-twentythree');
}

.ListInputIcon {
  position: absolute;
  top: 14px;
  left: 10px;
  @include theme-aware('stroke', 'color-two');
}

.ListInputIconError {
  @include theme-aware('stroke', 'color-twentythree');
}

.ListError {
  padding-bottom: 4px;
  @include theme-aware('color', 'color-twentythree');
}

.ListClose {
  position: absolute;
  box-shadow: 0 0 2px 0px;
  top: -14px;
  right: -14px;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  cursor: pointer;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.4s, stroke 0.4s, color 0.4s, background 0.4s;
  @include theme-aware('background', 'color-one');
  @include theme-aware('color', 'color-nine');
  @include theme-aware('stroke', 'color-twenty');
}

.ListClose:hover {
  box-shadow: 0 0 5px 0px;
  @include theme-aware('stroke', 'color-one');
  background: #FF3B30;
}
