@import "../../theme/mixins.scss";

.Renderer {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 6px;
  width: 100%;
  @include theme-aware('background', 'color-eighteen');
  object-fit: cover;
  overflow: hidden;
}

.Renderer img {
  display: block;
}

.Video {
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}

.Image {
  object-fit: cover;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.UnknownText {
  background: linear-gradient(304deg, #000000, #333333);
  color: #eeeeee;
  font-family: monospace !important;
  font-weight: bold;
  font-size: 62pt !important; 
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Text {
  background: linear-gradient(304deg, #D87B90, #AB5294);
  color: #eeeeee;
  font-family: 'Helvetica', 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 22pt;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.largeImage {
  box-shadow: 0 0 20px 7px #000;
  border: 1px solid red;
}

.pixelated {}