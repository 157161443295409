.Drops {
  background: #FFFFFF;
  box-shadow: 0 0 12px 7px rgba(0,0,0,0.06);
  border-radius: 8px;
  box-sizing: border-box;
  max-width: 335px;
  height: 100%;
  width: 100%;
  max-height: 447px;
  transition: box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.Drops:hover .DropsDataBackground {
  opacity: 1.0;
}

.Drops:hover .DropsNew {
	opacity: 0.0;
}

.DropsDataBackground {
	display: flex;
	box-sizing: border-box;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	opacity: 0.0;
	position: absolute;
	background: rgba(0, 0, 0, 0.4);
	width: 100%;
	height: 407px;
	bottom: 0px;
	left: 0px;
	transition: opacity 300ms;
	display: flex;
	font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 1.6px;
  text-align: center;
  line-height: 24px;
  text-shadow: 0 2px 4px rgba(0,0,0,0.50);
  cursor: pointer;
}

.DropsData{
	position: relative;
	width: 100%;
}

.DropsDataName {
	position: absolute;
	bottom: 30px;
	width: 100%;
	text-align: center;
	font-family: Roboto;
  font-weight: 700;
	font-size: 24px;
	color: #FFFFFF;
	letter-spacing: 1.85px;
	text-shadow: 0 0 15px rgba(0,0,0,0.60);
	z-index: 99;
}

.DropsNew { 
  position: absolute;
  bottom: 356px;
  left: 5px;
  background: rgba(50,215,75,0.20);
  border: 4px solid #32D74B;
  border-radius: 8px;
  font-family: Roboto;
  font-weight: 700;
  font-size: 24px;
  color: #32D74B;
  letter-spacing: 1.85px;
  text-align: center;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 0 12px 7px rgba(0,0,0,0.06);
  transition: opacity 300ms;
}

.DropsDataStats {
	opacity: 0.0;
	position: absolute;
	bottom: 10px;
	width: 100%;
	text-align: center;
	font-family: Roboto;
  font-weight: 700;
	font-size: 16px;
	color: #FFFFFF;
	letter-spacing: 1.85px;
	text-shadow: 0 0 7px rgba(0,0,0,0.75);
	z-index: 99;
}

.DropsPadding {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  width: 335px;
  height: 447px;
  position: relative;
}

.DropsImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.DropsPadding:hover .CollectionButton {
  opacity: 1.0;
}
