@import "../../theme/mixins.scss";

.Button {
  display: flex;
  flex-grow: 1;
  height: 42px;
  align-items: center;
  justify-content: center;
  padding-left: 13px;
  padding-right: 13px;
  cursor: pointer;
  transition: background 0.5s, color 0.5s, stroke 0.5s, border 0.5s;
}

/* Button Styles  */
.Primary {
  border-radius: 6px;
  box-sizing: border-box;
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1.23px;
  @include theme-aware('background', 'color-nineteen');
  @include theme-aware('color', 'color-twenty');
  @include theme-aware('stroke', 'color-twenty');
}

.Primary:hover {
  @include theme-aware('background', 'color-four');
}

.Secondary {
  border-radius: 6px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  font-family: Roboto;
  font-weight: 400;
  letter-spacing: 1.54px;
  font-size: 16px;
  height: 42px;
  @include theme-aware('border-color', 'color-three');
  @include theme-aware('background', 'color-one');
  @include theme-aware('color', 'color-two');
  @include theme-aware('stroke', 'color-two');
}

.Secondary:hover {
  font-family: Roboto;
  font-weight: 500;
  letter-spacing: 1.47px;
  border: 1px solid #0071E2;
  @include theme-aware('border-color', 'color-four');
  @include theme-aware('background', 'color-four');
  @include theme-aware('color', 'color-twenty');
  @include theme-aware('stroke', 'color-twenty');
}

.Error {
  background: #999999;
  border-radius: 6px;
  box-sizing: border-box;
  font-family: Roboto;
  font-weight: 500;
  letter-spacing: 1.23px;
  font-size: 16px;
  height: 42px;
  @include theme-aware('background', 'color-two');
  @include theme-aware('color', 'color-one');
  @include theme-aware('stroke', 'color-one');
}

.Error:hover {
  @include theme-aware('background', 'color-five');
  @include theme-aware('color', 'color-twenty');
  @include theme-aware('stroke', 'color-twenty');
}

/* Price */
.Price {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PriceSymbol {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  padding-right: 5px;
}

.PriceSpacer {
  flex-grow: 1;
}

.PriceValue {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Courier Prime';
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1.23px;
  text-align: right;
}

.PriceText {
  padding-left: 10px;
}
