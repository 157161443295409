@import "../../theme/mixins.scss";

@keyframes example {
  0% {opacity: 0.0;}
  50% {opacity: 0.0;}
  100% {opacity: 1.0;}
}

.Collections {
  box-shadow: 0 0 6px 3px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.4s;
  flex-direction: column;
  width: 430px;
  animation-name: example;
  animation-duration: 0.4s;
  @include theme-aware('color', 'color-nine');
  @include theme-aware('background', 'color-one');
}


.CollectionTopBar {
  padding: 20px;
  width: 430px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.CollectionsText {
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  color: #666666;
  letter-spacing: 1.23px;
  flex-grow: 1;
  text-align: left;
  box-sizing: border-box;
  @include theme-aware('color', 'color-twentyone');
}

.CollectionInput {
  border: 1px solid #979797;
  border-radius: 6px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1.23px;
  padding: 12px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  box-sizing: border-box;
  @include theme-aware('background', 'color-one');
  @include theme-aware('border-color', 'color-ten');
  @include theme-aware('color', 'color-two');
}

.CollectionsCarret {
  transition: transform 0.2s;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.CollectionPillContainer {
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 0px;
  width: 430px;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  animation-name: example;
  animation-duration: 0.4s;
  position: relative;
}

.CollectionListed {
  overflow-y: scroll;
  box-sizing: border-box;
  margin-bottom: 15px;
  margin-top: 15px;
  padding-right: 12px;
  width: 100%;
  flex-grow: 1;
}

.CollectionListed > * {
  margin-bottom: 4px;
}

.CollectionFilterInactive {
  opacity: 0.0;
}

.CollectionFilterActive {
  opacity: 1.0;
  cursor: pointer;
}

.CollectionFilterClear {
  position: absolute;
  top: 15px;
  right: 45px;
  transition: opacity 0.5s;
}

.CollectionFilterClear:hover {
  opacity: 0.7;
}

.CollectionsCarretOpen {
  transform: rotate(90deg);
}

.CollectionsCarretClose {
  transform: rotate(-90deg);
}

.CollectionContainer {
  transition: height 0.4s;
}

.CollectionOpen {
  height: calc(100vh - 300px);
}

.CollectionClosed {
  height: 0px;
}
