@import "../../theme/mixins.scss";

.ConfirmContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Confirm {
  box-shadow: 0 0 12px 7px rgba(0,0,0,0.06);
  width: 295px;
  height: 181px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  @include theme-aware('background', 'color-one');
  position: relative;
}

.ConfirmMessageContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ConfirmMessageText {
  font-family: Roboto;
  font-weight: 700;
  font-size: 18px;
  color: #999999;
  letter-spacing: 2.37px;
  text-align: center;
  padding-top: 10px;
}

.ConfirmClose {
  position: absolute;
  box-shadow: 0 0 2px 0px;
  top: -14px;
  right: -14px;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  cursor: pointer;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.4s, stroke 0.4s, color 0.4s, background 0.4s;
  @include theme-aware('background', 'color-one');
  @include theme-aware('color', 'color-nine');
  @include theme-aware('stroke', 'color-twenty');
}

.ConfirmClose:hover {
  box-shadow: 0 0 5px 0px;
  @include theme-aware('stroke', 'color-one');
  background: #FF3B30;
}