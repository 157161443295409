@import '../../theme/mixins.scss';

.imgWrapper {
    width: 45%;
    display: inline-block;
    vertical-align: top;
    padding: 32px 45px 32px 0;
    margin-bottom: 24px;
}

.metaWrapper {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding-bottom: 125px;
}

.trait {
    display: inline-block;
}

.nftData2 {
    width: 100%;
}

.metaWrapper h1 {
    font-size: 64px;
    letter-spacing: 4px;
    margin-bottom: 0px;
    margin-top: 32px;
    word-wrap: break-word;
    @include theme-aware('color', 'color-eleven');
}

.metaWrapper h2 {
    margin-bottom: 45px;
    letter-spacing: -0.02em;
    margin-top: 0;
    font-size: 24px;
    color: #90989c;
    font-style: italic;
}

.metaWrapper h3 {
    font-weight: 500;
    border-bottom: 1px solid #90989c;
    padding-bottom: 12px;
    letter-spacing: -0.02em;
    margin-bottom: 24px;
    font-size: 22px;
    @include theme-aware('color', 'color-eleven');
}

p {
    @include theme-aware('color', 'color-eleven');
}

.descriptionWrapper p {
    padding-left: 6px;
}

.ownerWrapper .ownerLink {
    padding-left: 6px;
}

.ownerWrapper a {
    word-wrap: break-word;
    text-decoration: none;
    @include theme-aware('color', 'color-eleven');
}

.ownerWrapper a:active, .ownerWrapper a:visited, .ownerWrapper a:focus {
    @include theme-aware('color', 'color-eleven');
}

.ownerWrapper a:hover {
    text-decoration: underline;
    @include theme-aware('color', 'color-eleven');
}

.Renderer {
    box-shadow: 0px 0px 20px 0px #121213;
}

.descriptionWrapper, .traitsWrapper, .rarityRankWrapper, .ownerWrapper {
    margin-bottom: 45px;
}

@media only screen
  and (max-width: 1023px) { 
    .imgWrapper {
        width: 100%;
        display: block;
        padding: 0;
    }
    
    .metaWrapper {
        width: 100%;
        display: block;
    }

    .metaWrapper h1 {
        font-size: 36px;
    }
}