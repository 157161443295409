@import "../../../theme/mixins.scss";

.CollectionElementIndividual {
  border-radius: 8px;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 2.13px;
  transition: background 0.2s, color 0.2s, fill 0.2s, stroke 0.2s;
  display: flex;
}

.Active {
  @include theme-aware('color', 'color-twenty');
  @include theme-aware('background', 'color-seven');
}

.Active circle {
  @include theme-aware('fill', 'color-twenty');
}

.Active g {
  @include theme-aware('stroke', 'color-seven');
}

.Inactive circle {
  @include theme-aware('fill', 'color-seven');
}

.Inactive {
  cursor: pointer;
  @include theme-aware('background', 'color-one');
  @include theme-aware('color', 'color-twentyone');
}

.Inactive:hover {
  @include theme-aware('background', 'element-hover-color');
}

.CollectionElementIcon {
  box-shadow: 0 0 2px 0;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  overflow: hidden;
  position: relative;
  @include theme-aware('color', 'color-fourteen');
  @include theme-aware('background', 'color-thirteen');
}

.CollectionElementText {
  margin-left: 20px;
}

.CollectionElementVerified {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.CollectionElementIconSrc {
  position: absolute;
  width: 100%;
  height: 100%;
}