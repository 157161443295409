@import '../../theme/mixins.scss';

@media only screen
  and (min-width: 1024px) { 
  .HomePage {
    height: calc(100vh - 125px);
    display: flex;
  }
}

@media only screen
  and (max-width: 1023px) { 
  .HomePage {
  }
}

h1 {
  font-family: Roboto;
  font-weight: 500;
  font-size: 45px;
  margin-bottom: 12px;
  @include theme-aware('color', 'color-twentyone');
}


h2 {
  font-family: Roboto;
  font-weight: 400;
  letter-spacing: 1.54px;
  @include theme-aware('color', 'color-two');
}

@media only screen
  and (min-width: 1024px) { 
  .HomePageView {
    padding-left: 10px;
    padding-right: 30px;
    min-width: 540px;
    display: flex;
    flex-direction: column;
  }
}

.HomePageInputContainer {
  padding-bottom: 18px;
}

.HomePageInputContainer > * {
  margin-top: 15px;
}

.HomePageSpacer {
  height: 6%;
}

hr {
  opacity: 0.1;
}

.HomePageInput {
  border: 1px solid #979797;
  border-radius: 6px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1.23px;
  padding: 12px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  box-sizing: border-box;
  @include theme-aware('border-color', 'color-fifteen');
  @include theme-aware('color', 'color-two');
}

.HomePageCollectionsView {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: scroll;
}

.Highlight {
  @include theme-aware('color', 'color-seven');
}