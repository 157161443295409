.StacculentsAdopt span {
    flex: 1;
    background-image: linear-gradient(-135deg,transparent,#6F42DA);
    background-color: #c85ab9;
    border-radius: inherit;
    padding: 0 18px;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: inset 0 -6px #0003;
    transition: .2s background-color;
    height: auto;
    min-height: 60px;
}

@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid&display=swap');

.StacculentsAdopt {
  display: inline-flex;
  position: relative;

  font: 30px/60px Londrina Solid;
  text-transform: uppercase;

  color: #fff;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;

  background: linear-gradient(-135deg, #FFD381, #FF677E);

  border-radius: 50px;
  box-sizing: border-box;

  box-shadow: 0 4px 10px rgba(0, 0, 0, .2);

  transition: 200ms box-shadow, 200ms transform;
  will-change: box-shadow, transform;
}

@media only screen
  and (max-width: 1023px) { 
  .StacculentsAdopt {
    font: 18px/60px Londrina Solid;
  }
}

.StacculentsAdopt:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 14px rgba(0, 0, 0, .2);
}

.StacculentsAdopt:hover span {
  background-color: #DD55CA;
}

.StacculentsAdopt:active {
  transition: 200ms box-shadow;
  transform: translateY(0);
}

.StacculentsAdopt:active span {
  background: #6F42DA;
  transition: none;
}