.flip_box {
  background-color: transparent;
  width: 295px;
  height: 407px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip_box_inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip_box_inner_flipped {
	transform: rotateY(180deg);
}

/* Position the front and back side */
.flip_box_front, .flip_box_back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side */
.flip_box_front {
  color: black;
}

/* Style the back side */
.flip_box_back {
  color: #FFFFFF;
  transform: rotateY(180deg);
}